import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import Button from '../components/Button'
import Content from '../components/Content'
import GlobalContext from '../utils/globalContext'
import Icon from '../components/Icon'
import Root from '../layouts/Root'

import config from '../config'

import styles from './branches.module.scss'

export default ({ data }) => {
  const { frontmatter, html, excerpt } = data.markdownRemark

  return (
    <Root>
      <GlobalContext.Consumer>
        {({ i18n }) => (
          <>
            {/* prettier-ignore */}
            <Helmet>
              <title>{`${frontmatter.meta_title || frontmatter.title} | ${config.title}`}</title>
              <meta name="description" content={frontmatter.meta_description || excerpt} />
              <meta property="og:title" content={`${frontmatter.meta_title || frontmatter.title} | ${config.title}`} />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={frontmatter.meta_description || excerpt} />
              {frontmatter.cover && <meta property="og:image" content={config.siteUrl + frontmatter.cover.childImageSharp.fluid.src} />}
              {frontmatter.cover && <meta property="og:image:height" content={frontmatter.cover.childImageSharp.original.height} />}
              {frontmatter.cover && <meta property="og:image:width" content={frontmatter.cover.childImageSharp.original.width} />}
            </Helmet>

            <main>
              <Content
                title={frontmatter.title}
                titleBlack
                /* prettier-ignore */
                image={frontmatter.cover ? frontmatter.cover.childImageSharp.fluid : null}
                image_alt={frontmatter.cover_alt || frontmatter.title}
                /*prettier-ignore */
                cta={() =>
                <>
                  <div className={styles.moreInfo}>Meer informatie<br /><a href={`tel:${i18n.misc.phoneHtml}`} className={styles.moreInfoPhone}><Icon name="phone" size={24} /> {i18n.misc.phoneHuman}</a></div>
                  <Button size="large" to="/contact/" type="link">Neem vrijblijvend contact met ons op</Button>
                </>
              }
              >
                {frontmatter.diensten.map((dienst) => (
                  <Link
                    className={styles.dienst}
                    key={dienst.title}
                    to={`/diensten/${dienst.slug}/`}
                  >
                    {dienst.title}
                  </Link>
                ))}
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Content>
            </main>
          </>
        )}
      </GlobalContext.Consumer>
    </Root>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        diensten {
          title
          slug
        }
        meta_title
        meta_description
        cover_alt
        cover {
          childImageSharp {
            original {
              height
              width
            }
            fluid(maxWidth: 800, maxHeight: 600, quality: 75, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
